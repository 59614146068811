.featureContainer{
    display: flex;
    color: white;
    justify-content: center;
    margin-top: 30px;
}

.featureContainer :not(div:first-child){
    margin-top: 50px;
}


.featureContent{
    align-self: center;
}

.featureContentContainerLeft{
    flex-direction: column-reverse;
}

.featureContentContainerRight{
    flex-direction: column-reverse;

}

.featureContentLeft{
    margin-top: 30px;
}

.featureContentRight{
    margin-top: 30px;
}

.featureTitle{
    font-size: 24px;
}

.featureDescription {
    font-size: 20px;
    font-weight: lighter;
}

.featureImage{
    height: 100%;
    width: 100%;
    max-height: 500px;
    max-width: 408px;
    align-self:center;
}

.featureButton{
    min-width: 154px;
    border: none;
    padding: 10px;
    color: white;
    background: transparent linear-gradient(269deg, #FF8900 0, #FFE000 100%) 0 0 no-repeat padding-box;
    box-shadow: 0 16px 32px #FF890080;
    border-radius: 15px;
    opacity: 1;
    cursor: pointer;
    outline: none;
}



@media (min-width: 480px) {

}

@media (min-width: 768px) {
    .featureContainer{
        margin-top: 150px;
    }

    .featureContentContainerLeft{
        flex-direction: row;
    }

    .featureContentContainerRight{
        flex-direction: row-reverse;

    }

}

@media (min-width: 992px) {

    .featureContentLeft{
        padding-right: 200px;
    }

    .featureContentRight{
        padding-left: 200px;
    }
}

@media (min-width: 1200px) {

}
