.input{
    padding: 24px 25px;
    border-radius: 15px;
    height: 28px;
    border: none;
    margin-right: 10px;
    font-size: 20px;
    width: 100%;
    outline: none;
}

.input::placeholder{
    color: #9C9C9C;
    /*font-weight: lighter;*/
}
