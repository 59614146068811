.dashboard{
    min-height: 100vh;
}

.dashWrapper{
    display: flex;
    margin-top: 3em;
    flex-direction: column-reverse;
    margin-left: 21px;
    margin-right: 21px;
}

.mobileButton{
    background: transparent linear-gradient(267deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    position: fixed !important;
    bottom: 25px;
    right: 25px;
}


@media (min-width: 992px) {
    .mobileButton{
        display: none;
    }
    .dashWrapper{
        flex-direction: row;
    }
}


