.tos{
    cursor: pointer;
}

.mobileSelect{
    position: absolute;
    top:125px;
    margin-left: 20px;
    width: 35%;
}

.smallLogo{
    position: absolute;
    transform: scale(.5);
    height: 94px;
    width: auto;
}

.smallLogo:hover{
    cursor: pointer;
}

/* Dropdown Button */
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: none;
    position: absolute;
    background-color: #1F2231;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
}

/* Links inside the dropdown */
.dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {
    background-color: #ddd;
    cursor: pointer;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
    display: block;
}

.logOut{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logOut >div{
    margin-right: 10px;
}

.smallPfp{
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.paymentImage{
    height: 35px;
    width: 35px;
}

.paymentImageTwitterCrop{
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.avatar{
    border-radius: 50%;
}







