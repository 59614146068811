

.mainContent{
background-color: #161822;
    /*height: 100vh;*/
}


.secondaryContent{
    background-color: #161822;
}

.secondaryContent svg{
    position: relative;
    top: -50px;
}

.sites{
    margin: 100px auto;
    display: flex;
    color: rgb(255, 179, 0);
    list-style-type: none;
    justify-content: space-between;
    flex-direction: column;
}

.sitesHeading{
    font-size: 18px;
    color: #FFB300;
    list-style-type: none;
    display: flex;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    
}

.sitesHeading li{
    padding: 0 10px;
    cursor: pointer;
}

.cardSection{
    display: flex;
    justify-content: center;
    margin: 50px 65px;
    max-width: 1620px;
    flex-wrap: wrap;
}


/* =========== SPECIAL FEATURES STARTS HERE ======== */

.specFeaturesContainer{
    max-width: 1165px;
    margin: 0 auto;
}

.specFeaturesDescription{
    max-width: 720px;
    margin: 0 auto;
    text-align:center;
}

.specFeaturesTitle{
    font-size: 48px;
    font-weight: 600;
    color: white;
}

.specFeaturesSecondaryText{
    color: #9C9C9C;
    font-size: 32px;
    line-height: 1.5em;
}

/* =========== SPECIAL FEATURES ENDS HERE ======== */



/* =========== NEWSLETTER STARTS HERE ======== */

.newsLetter{
    margin-top: 80px;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.newsLetterText{
    color: white;
}

.newsLetterPrimary{
    font-size: 15px;
}

.newsLetterSecondary{
font-size: 10px;
}

.contactForm{
    align-self: center;
    margin-top: 50px;
}

.contactFormContainer{
    display: flex;
    flex-direction: column;
}

.contactInput{
    padding: 24px 25px;
    border-radius: 15px;
    height: 56px;
    border: none;
    font-size: 20px;
    margin-bottom: 26px;
    outline: none;
}

.contactInput::placeholder{
   color: #9C9C9C;
    font-weight: lighter;
}

.contactBtn {
    color: white;
    background: #FF8900;
    border: none;
    border-radius: 15px;
    padding: 10px 25px;
    font-weight: 500;
    font-size: 24px;
    outline: none;
}

/* =========== NEWSLETTER ENDS HERE ======== */



.selectedCat{
    text-decoration: underline;
}


@media (min-width: 480px) {
    .sitesHeading{
        font-size: 18px;
    }

}

@media (min-width: 768px) {
    .sitesHeading{
        font-size: 28px;
    }
    .newsLetterPrimary{
        font-size: 48px;
    }

    .newsLetterSecondary{
        font-size: 20px;
        width: 300px;
    }

}

@media (min-width: 992px) {
    .sitesHeading{
        font-size: 32px;
    }

    .cardSection{
        margin: 50px 200px;
    }

    .newsLetter{
        border-radius: 32px;
        background: url('/bg-swirl@2x.png') no-repeat;
        margin-top: 305px;
        margin-left: 50px;
        margin-right: 50px;
        background-size: cover;
        background-position-y: center;
        display: flex;
        padding: 24px 76px;
        justify-content: space-between;
        max-height: 284px;
    }

    .contactForm{
        margin-left: 100px;
        margin-top: 0;
        align-self: center;
    }
    .contactInput{
        height: 28px;
        font-size: 20px;
        margin-bottom: 15px;
    }

    .contactBtn {
        background: black;
    }
}


@media (min-width: 1200px) {
    .newsLetter{
        padding: 46px 76px;
        margin-right: 150px;
        margin-left: 150px;
    }

    .contactFormContainer{
        display: block;
    }
    .contactInput{
        height: 28px;
        margin-right: 10px;
        font-size: 20px;
        margin-bottom: 0;
    }
}
