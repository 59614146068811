.topBar{
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    list-style-type: none;
    width: 100%;
    margin: auto;
    margin-top: 5em;
}

.topBarMobile{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 20px;
    list-style-type: none;
    width: 100%;
    margin-top: 5em;
}


.topBar > button{
 text-align: center;
 background: none;
 color: #FFB300;
 font-size: 20px;
 font-family: inherit;
 outline:none;
 border: 0px;
 cursor: pointer;
 margin-right: 2%;
 margin-left: 2%;
}

.topBarMobile > button{
    text-align: center;
    background: none;
    color: #FFB300;
    font-size: 20px;
    font-family: inherit;
    outline:none;
    border: 0px;
    cursor: pointer;
   
   }

.underlined{
    text-decoration: underline;
}