.homeFooter{
    margin-top: 230px;
    margin-left: 32px;
    margin-right: 32px;
    max-width: 100%;
    margin-bottom: 50px;
    background-color: #161822;
    display: flex;
    justify-content: space-evenly;
    font-size: 15px;
    color: white;
    left: 0;
    bottom: 0;
    height: auto;
    flex-direction: column;
}


.homeFooter a{
    text-decoration: none;
    color: white;
}

.homeFooter a:visited{
    text-decoration: none;
    color: white;
}

.homeFooter a:hover{
    cursor: pointer;
}


.homeFooter > div:not(:first-child) {
    margin-top: 10px;
}


.copyRightText{
    color: #9C9C9C;
    font-size: 16px;
    text-align: center;
}

.footerList{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 16px;
    flex-wrap: wrap;
}

.footerLogo{
width:50px;
    height:50px
}

.footerList li{
    padding: 0 15px;
}

.socialIcons{
    justify-content: flex-end;
    align-items: flex-end;
}

.socialIcons li:not(:last-child){
    padding: 0 15px;
}

@media (min-width: 480px) {
.copyRightText{
    display: flex;
    justify-content: center;
}
}

@media (min-width: 768px) {


}

@media (min-width: 992px) {

}


@media (min-width: 1200px) {

}
