.headerContainer{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    padding: 50px;
    padding-top: 7%;
    padding-bottom: 5%;
    max-width: 80%;
    position: relative;
    align-items:flex-start;

}


.headerMainContent{
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*margin-right: -50%;*/
    /*transform: translate(-50%, -50%);*/
    align-items:flex-start;
    text-align:center;
    display: flex;
    justify-content: center;
    margin: 0 20px;
    margin-bottom: 5%;
}

.headerImg{
    
    width: 676px;
    -webkit-transform: translateZ(0); transform: translateZ(0);
    max-width: 100%;
    height: auto;
}



.headerSearchField{
    position: absolute;
    top: 175px;
    height: 63px;
    width: 430px;
    padding: 13px 25px;
    border-radius: 8px;
    border: none;
    background: #F5F5F5;
    color: black;
    font-family: inherit;
    font-size: 18px;
    right: 243px;
    outline: none;
}

.headerSearchBtn{
    background-color: black;
    color: white;
    position: absolute;
    top: 175px;
    height: 63px;
    width: 76px;
    right: 243px;
    border-radius: 0 8px 8px 0;
    border:none;
    cursor: pointer;
    outline: none;
}

.headerBanner{
    margin-left: 7.5%;
    margin-top:30px;
    width: 85%;
    height: auto;
    position: absolute;
}

.motto{
    position: absolute;
    color: white;
    font-size: 36px;
    margin-left: 50px;
    font-family: inherit;
    font-weight: bold;
    margin-top: -20px;
 
}

.mottoTop{

}

.mottoMid{
    background-color: orange;
}

.mottoBottom{
    font-size: 20px;
    font-weight: normal;
    margin-top: 10px;
}


@media all and (max-width: 1000px) {
    .headerContainer{
        flex-direction: column;
        padding: 50px;
    }
    .headerSearchField {
        margin-top: 30px;
        width: 100%;
        position: static;
        top: 0;


    }
    .headerSearchBtn {
        top:30px;
        right: 0;
    }

    .motto{
        font-size: 23px;
        margin-left: 10px;
    }
    
    .mottoBottom{
        font-size: 15px;
    }
}

@media (max-width: 400px) {

    .motto{
        font-size: 5vw;
        margin-left: 10px;
    }
    
    .mottoBottom{
        font-size: 3vw;
    }
}
