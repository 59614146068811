.sidebar{
    margin: 0 40px;
    align-self: center;
}

.mobileButton{
    background: transparent linear-gradient(267deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    position: fixed !important;
    bottom: 25px;
    right: 25px;
}

.browseButton{
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border: 0;
    color: #FFFFFF;
    height: 48px;
    padding: 0 30px;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 16px 32px #FF890080;
    cursor: pointer;
    outline: none;
}

.catSelect{
    margin-top: 30px;
}

.filtersWrapper{
    margin-top: 20px;
}

.buttonWidth{
    width:240px;
}

.filterListContainer{
    display: flex;
    flex-direction: row-reverse;
}

.filterListHeaderMobile{
    font-size: 20px;
    color: #8D8D8D;
    cursor: pointer;
    position: absolute;
    right:20px;
   top  :8em;
}

.filterGroup{
    display: flex;
    flex-direction: column;
    padding: 0;
}

.filterGroup li {
    list-style: none;
}

.filterRadioBtn{
    color: white;

}

@media (min-width: 480px) {
    .filterListContainer{
        display: block;
    }
    .filterListHeader{
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 22px;
        
    }
}

@media (min-width: 992px) {
    .sidebar{
        align-self: auto;
    }
    .filterListHeader{
        cursor: inherit;
        margin-top: 22px;
    }

    .filtersWrapper{
        margin-top: 80px;
    }
}


