.btn{
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border: 0;
    color: #FFFFFF;
    height: 48px;
    padding: 0 30px;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 16px 32px #FF890080;
    cursor: pointer;
    font-family: inherit;
    outline: none;
}


.btnOutlined{
    background-color: transparent;
    border-radius: 15px;
    border: 0;
    color: #FFFFFF;
    height: 48px;
    padding: 0 30px;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 16px 32px #FF890080;
    cursor: pointer;
    border: 1px solid white;
    font-family: inherit;
    outline: none;
}
