.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}


.popupInner {
    position: absolute;
    background-color: #1F2231;
    margin: auto;
    border-radius: 10px;
    padding: 3em;
}

.popupInner h1{
    color: white;
}

.popupInner .listingImage > img{
    margin-left: 10px;
    margin-top: 10px;
    width: 12vw;
    height:12vw;
}

.popupInner > .listingDescription{
    height: 30vh;
}


.popupInnerMobile{
    background: #1F2231;
    position: absolute;
    width: 95%;
    top: 10%;
    bottom: 10%;
    margin: 0 34px;
    display: inline-block;
    border-radius: 15px;
    overflow-y: scroll;
    padding: 35px;
}

.popupInnerMobile::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  
  }
  
  .popupInnerMobile::-webkit-scrollbar-track {
    opacity: 0;
   
  }
  
  .popupInnerMobile::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }


.popupInnerMobile > h5{
    position: absolute;
    bottom: 0;
}


.popupInnerMobile .listingImage > img{
    margin-left: 10px;
    margin-top: 20px;
}

.popupInnerMobile > .listingDescription{
    height: 40vh;
    margin: 3px;
}

.popupTitle{
    font-size: 28px;
    color: white;
}

.closeButton
{
    color: white;
    width: 80px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.closeButton:hover{
    cursor: pointer;
}

.textArea{
    padding: 10px 14px 25px 25px !important;
    font-size: 20px;
    background: white;
    font-family: inherit;
    resize: none;
    border-radius: 15px;
    width: 100%;
    height: 108px;
    margin: 0;
    outline: none;
    margin-bottom: 10px;
    display: block;
    border:0px
}

.textArea::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  
  }
  
  .textArea::-webkit-scrollbar-track {
    opacity: 0;
   
  }
  
  .textArea::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }

.textArea::placeholder{
    color: #9C9C9C;
}

.listingInfoContainer{
    margin-top: 40px;
    flex-direction: column;
}

.listingInputWrapper{
    margin-bottom: 10px;
    margin-right: 10px;
    flex: 1;
}

.select{
    max-width: 100%;
}

.listingSelectContainer{
    display:flex;
    flex-direction: column;
}

.typeContainer *{

    width: 50%;
  
}

.buttonContainer{

}

.buttonContainer button{
    font-size: 20px;
    padding: 7px 15px;
    width: 50%;
    margin-right: 10px;
}

@media (min-width: 768px) {
    .listingInfoContainer{
        flex-direction: row;
    }
    .listingSelectContainer{
        flex-direction: column;
    }
}
