.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}


.popupInner {
    position: absolute;
    background-color: #1F2231;
    margin: auto;
    border-radius: 10px;
    padding: 3em;
}

.popupInnerMobile{
    background: #1F2231;
    position: absolute;
    margin:auto;
    display: inline-block;
    border-radius: 15px;
    overflow-y: scroll;
    padding: 35px;
}

.popupInnerMobile::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  
}
  
.popupInnerMobile::-webkit-scrollbar-track {
    opacity: 0;
   
}
  
.popupInnerMobile::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.closeButton
{
    color: white;
    width: 80px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.closeButton:hover{
    cursor: pointer;
}

.popupTitle{
    font-size: 28px;
    color: white;
}

.shareButton {
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    cursor: pointer;
    width: 200px;
    padding: 1em;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    text-align: center;
    margin-top: 10px;
}

.support{
    width: 200px;
    color: white;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .shareButton{
        width: 400px;
    }

    .support{
        width: 400px;
    }
}
