.toast{
    background:  #1F2231;
    color: white;
    border-radius: 15px;
    text-align: center;
    font-family: inherit;
}

.toastBody{
    color: white;
}

.progressBar{
    background:  linear-gradient(to bottom, #FFE000 0%,#FF8900 100%) no-repeat top left;
    border-radius: 15px;
}