.listingWrapper{
    background-color: #1F2231;
    border-radius: 15px;
    padding: 17px 10px;
    width: 100%;
    max-height: 78px;
}

.listingImage{
    height: 57px;
    width: 57px;
    border-radius: 50%;
}

.listingPrice{
    margin-left: 15px;
    /*font-size: 32px;*/
    font-size: 23px;
color:#FFB300;
}

.listingTitle{
    margin-left: 15px;
    text-align: center;
    color: white;
    /*font-size: 24px;*/
    font-size: 23px;
}

.listingActionsContainer{

}

.listingActionsContainer button{
    color: #9C9C9C;
    font-family: inherit;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: lighter;
}

.listingActionsContainer button:first-child{
}

.moreButton{
    color: white;
}


@media (min-width: 480px) {

}

@media (min-width: 768px) {
    .listingWrapper{
        padding: 25px 40px 25px 30px;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {

}


@media (min-width: 1200px) {
    .listingActionsContainer button:first-child{
        margin-right: 122px;
    }
}


