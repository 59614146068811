.title{
    font-size: 48px;
    color: white;
}

.mobileTitle{
    color: white;
}
h1{
    color: white;
}

.loadMoreContainer{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.mobileProductContainer{
    margin-top: 36px;
}
.productContainer{
    width: 65%;
}

.products{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


.products > div:hover{
    cursor: pointer;
    background-color: rgb(230, 230, 230);
}

.spinner{
    margin-top: 10em;
}

.spinnerMobile{
   justify-content: center;
   margin-top: 30%;
}


@media (min-width: 480px) {
    .productContainer{
        width: 100%;
    }
}
