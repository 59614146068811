.tosHeader{
    margin-top: 100px;
    margin-left: 40px;
}
  
.termsList{
    margin-left: 40px;
}
  
.terms{
    height: 100vh;
    color: white;
}
