@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  background-color: #161822 !important;
}

body{
  max-width: 1920px;
}

@media only screen and (min-width : 1919px) {
  body{
    margin: 0 auto !important;
  }
}

::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
     /* color of the tracking area */
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

code {
  
}

.menu {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.menuDark{
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.listItem{
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}


.menu li {
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.menuDark li {
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.navLink{
  text-decoration:none;
}

.navLink:visited{
  text-decoration: none;
  color: white;
}

.navLink:hover{
  cursor: pointer; 
}

.listItem{
  text-decoration: none;
  color: black;
}

.listItem:visited{
  text-decoration: none;
  color: black;
}

.listItem:hover{
  cursor: pointer; 
}

nav{
  position: fixed;
  background-color: #404040;
  width: 100%;
  z-index: 99 !important;
  box-shadow: 0 6px 15px -6px rgba(0,0,0,0.75);
}

.header_headerContainer__2ylA4{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    padding: 50px;
    padding-top: 7%;
    padding-bottom: 5%;
    max-width: 80%;
    position: relative;
    align-items:flex-start;

}


.header_headerMainContent__1fq2V{
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*margin-right: -50%;*/
    /*transform: translate(-50%, -50%);*/
    align-items:flex-start;
    text-align:center;
    display: flex;
    justify-content: center;
    margin: 0 20px;
    margin-bottom: 5%;
}

.header_headerImg__1BCGl{
    
    width: 676px; transform: translateZ(0);
    max-width: 100%;
    height: auto;
}



.header_headerSearchField__2k4cy{
    position: absolute;
    top: 175px;
    height: 63px;
    width: 430px;
    padding: 13px 25px;
    border-radius: 8px;
    border: none;
    background: #F5F5F5;
    color: black;
    font-family: inherit;
    font-size: 18px;
    right: 243px;
    outline: none;
}

.header_headerSearchBtn__25Txd{
    background-color: black;
    color: white;
    position: absolute;
    top: 175px;
    height: 63px;
    width: 76px;
    right: 243px;
    border-radius: 0 8px 8px 0;
    border:none;
    cursor: pointer;
    outline: none;
}

.header_headerBanner__3ilao{
    margin-left: 7.5%;
    margin-top:30px;
    width: 85%;
    height: auto;
    position: absolute;
}

.header_motto__13di3{
    position: absolute;
    color: white;
    font-size: 36px;
    margin-left: 50px;
    font-family: inherit;
    font-weight: bold;
    margin-top: -20px;
 
}

.header_mottoTop__3IZ67{

}

.header_mottoMid__L7_VB{
    background-color: orange;
}

.header_mottoBottom__xAhat{
    font-size: 20px;
    font-weight: normal;
    margin-top: 10px;
}


@media all and (max-width: 1000px) {
    .header_headerContainer__2ylA4{
        flex-direction: column;
        padding: 50px;
    }
    .header_headerSearchField__2k4cy {
        margin-top: 30px;
        width: 100%;
        position: static;
        top: 0;


    }
    .header_headerSearchBtn__25Txd {
        top:30px;
        right: 0;
    }

    .header_motto__13di3{
        font-size: 23px;
        margin-left: 10px;
    }
    
    .header_mottoBottom__xAhat{
        font-size: 15px;
    }
}

@media (max-width: 400px) {

    .header_motto__13di3{
        font-size: 5vw;
        margin-left: 10px;
    }
    
    .header_mottoBottom__xAhat{
        font-size: 3vw;
    }
}

.footer_homeFooter__3tK1G{
    margin-top: 230px;
    margin-left: 32px;
    margin-right: 32px;
    max-width: 100%;
    margin-bottom: 50px;
    background-color: #161822;
    display: flex;
    justify-content: space-evenly;
    font-size: 15px;
    color: white;
    left: 0;
    bottom: 0;
    height: auto;
    flex-direction: column;
}


.footer_homeFooter__3tK1G a{
    text-decoration: none;
    color: white;
}

.footer_homeFooter__3tK1G a:visited{
    text-decoration: none;
    color: white;
}

.footer_homeFooter__3tK1G a:hover{
    cursor: pointer;
}


.footer_homeFooter__3tK1G > div:not(:first-child) {
    margin-top: 10px;
}


.footer_copyRightText__1oT_t{
    color: #9C9C9C;
    font-size: 16px;
    text-align: center;
}

.footer_footerList__3mnzx{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 16px;
    flex-wrap: wrap;
}

.footer_footerLogo__2tZ2y{
width:50px;
    height:50px
}

.footer_footerList__3mnzx li{
    padding: 0 15px;
}

.footer_socialIcons__3gZQq{
    justify-content: flex-end;
    align-items: flex-end;
}

.footer_socialIcons__3gZQq li:not(:last-child){
    padding: 0 15px;
}

@media (min-width: 480px) {
.footer_copyRightText__1oT_t{
    display: flex;
    justify-content: center;
}
}

@media (min-width: 768px) {


}

@media (min-width: 992px) {

}


@media (min-width: 1200px) {

}

.navbar_tos__31IPG{
    cursor: pointer;
}

.navbar_mobileSelect__3oRjX{
    position: absolute;
    top:125px;
    margin-left: 20px;
    width: 35%;
}

.navbar_smallLogo__1dk-J{
    position: absolute;
    transform: scale(.5);
    height: 94px;
    width: auto;
}

.navbar_smallLogo__1dk-J:hover{
    cursor: pointer;
}

/* Dropdown Button */
.navbar_dropbtn__XjX1v {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.navbar_dropdown__IaJ6j {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.navbar_dropdownContent__3SIFQ {
    display: none;
    position: absolute;
    background-color: #1F2231;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
}

/* Links inside the dropdown */
.navbar_dropdownContent__3SIFQ a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.navbar_dropdownContent__3SIFQ a:hover {
    background-color: #ddd;
    cursor: pointer;
}

/* Show the dropdown menu on hover */
.navbar_dropdown__IaJ6j:hover .navbar_dropdownContent__3SIFQ {
    display: block;
}

.navbar_logOut__3TARq{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar_logOut__3TARq >div{
    margin-right: 10px;
}

.navbar_smallPfp__2Idox{
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.navbar_paymentImage__3XJPX{
    height: 35px;
    width: 35px;
}

.navbar_paymentImageTwitterCrop__lIHlq{
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.navbar_avatar__118dk{
    border-radius: 50%;
}








.featureblock_featureContainer__1asp2{
    display: flex;
    color: white;
    justify-content: center;
    margin-top: 30px;
}

.featureblock_featureContainer__1asp2 :not(div:first-child){
    margin-top: 50px;
}


.featureblock_featureContent__nvs4c{
    align-self: center;
}

.featureblock_featureContentContainerLeft__3_Yxw{
    flex-direction: column-reverse;
}

.featureblock_featureContentContainerRight__1Xw1R{
    flex-direction: column-reverse;

}

.featureblock_featureContentLeft__2fjRB{
    margin-top: 30px;
}

.featureblock_featureContentRight__2QUSv{
    margin-top: 30px;
}

.featureblock_featureTitle__3o364{
    font-size: 24px;
}

.featureblock_featureDescription__3BLyk {
    font-size: 20px;
    font-weight: lighter;
}

.featureblock_featureImage__3vkcX{
    height: 100%;
    width: 100%;
    max-height: 500px;
    max-width: 408px;
    align-self:center;
}

.featureblock_featureButton__2-1D4{
    min-width: 154px;
    border: none;
    padding: 10px;
    color: white;
    background: transparent linear-gradient(269deg, #FF8900 0, #FFE000 100%) 0 0 no-repeat padding-box;
    box-shadow: 0 16px 32px #FF890080;
    border-radius: 15px;
    opacity: 1;
    cursor: pointer;
    outline: none;
}



@media (min-width: 480px) {

}

@media (min-width: 768px) {
    .featureblock_featureContainer__1asp2{
        margin-top: 150px;
    }

    .featureblock_featureContentContainerLeft__3_Yxw{
        flex-direction: row;
    }

    .featureblock_featureContentContainerRight__1Xw1R{
        flex-direction: row-reverse;

    }

}

@media (min-width: 992px) {

    .featureblock_featureContentLeft__2fjRB{
        padding-right: 200px;
    }

    .featureblock_featureContentRight__2QUSv{
        padding-left: 200px;
    }
}

@media (min-width: 1200px) {

}

.button_btn__17lZw{
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border: 0;
    color: #FFFFFF;
    height: 48px;
    padding: 0 30px;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 16px 32px #FF890080;
    cursor: pointer;
    font-family: inherit;
    outline: none;
}


.button_btnOutlined__3erc_{
    background-color: transparent;
    border-radius: 15px;
    border: 0;
    color: #FFFFFF;
    height: 48px;
    padding: 0 30px;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 16px 32px #FF890080;
    cursor: pointer;
    border: 1px solid white;
    font-family: inherit;
    outline: none;
}



.home_mainContent__PvSK2{
background-color: #161822;
    /*height: 100vh;*/
}


.home_secondaryContent__3Gt3O{
    background-color: #161822;
}

.home_secondaryContent__3Gt3O svg{
    position: relative;
    top: -50px;
}

.home_sites__13BWv{
    margin: 100px auto;
    display: flex;
    color: rgb(255, 179, 0);
    list-style-type: none;
    justify-content: space-between;
    flex-direction: column;
}

.home_sitesHeading__kSoWU{
    font-size: 18px;
    color: #FFB300;
    list-style-type: none;
    display: flex;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    
}

.home_sitesHeading__kSoWU li{
    padding: 0 10px;
    cursor: pointer;
}

.home_cardSection__307iZ{
    display: flex;
    justify-content: center;
    margin: 50px 65px;
    max-width: 1620px;
    flex-wrap: wrap;
}


/* =========== SPECIAL FEATURES STARTS HERE ======== */

.home_specFeaturesContainer__2eva-{
    max-width: 1165px;
    margin: 0 auto;
}

.home_specFeaturesDescription__2Wj1b{
    max-width: 720px;
    margin: 0 auto;
    text-align:center;
}

.home_specFeaturesTitle__1I5GU{
    font-size: 48px;
    font-weight: 600;
    color: white;
}

.home_specFeaturesSecondaryText__1Q_zT{
    color: #9C9C9C;
    font-size: 32px;
    line-height: 1.5em;
}

/* =========== SPECIAL FEATURES ENDS HERE ======== */



/* =========== NEWSLETTER STARTS HERE ======== */

.home_newsLetter__1Jgpf{
    margin-top: 80px;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.home_newsLetterText__2DUZQ{
    color: white;
}

.home_newsLetterPrimary__3tMSZ{
    font-size: 15px;
}

.home_newsLetterSecondary__uAi__{
font-size: 10px;
}

.home_contactForm__1bzb-{
    align-self: center;
    margin-top: 50px;
}

.home_contactFormContainer__1WPPF{
    display: flex;
    flex-direction: column;
}

.home_contactInput__W2HXd{
    padding: 24px 25px;
    border-radius: 15px;
    height: 56px;
    border: none;
    font-size: 20px;
    margin-bottom: 26px;
    outline: none;
}

.home_contactInput__W2HXd::-webkit-input-placeholder{
   color: #9C9C9C;
    font-weight: lighter;
}

.home_contactInput__W2HXd::-ms-input-placeholder{
   color: #9C9C9C;
    font-weight: lighter;
}

.home_contactInput__W2HXd::placeholder{
   color: #9C9C9C;
    font-weight: lighter;
}

.home_contactBtn__3wX0K {
    color: white;
    background: #FF8900;
    border: none;
    border-radius: 15px;
    padding: 10px 25px;
    font-weight: 500;
    font-size: 24px;
    outline: none;
}

/* =========== NEWSLETTER ENDS HERE ======== */



.home_selectedCat__2vRag{
    text-decoration: underline;
}


@media (min-width: 480px) {
    .home_sitesHeading__kSoWU{
        font-size: 18px;
    }

}

@media (min-width: 768px) {
    .home_sitesHeading__kSoWU{
        font-size: 28px;
    }
    .home_newsLetterPrimary__3tMSZ{
        font-size: 48px;
    }

    .home_newsLetterSecondary__uAi__{
        font-size: 20px;
        width: 300px;
    }

}

@media (min-width: 992px) {
    .home_sitesHeading__kSoWU{
        font-size: 32px;
    }

    .home_cardSection__307iZ{
        margin: 50px 200px;
    }

    .home_newsLetter__1Jgpf{
        border-radius: 32px;
        background: url('/bg-swirl@2x.png') no-repeat;
        margin-top: 305px;
        margin-left: 50px;
        margin-right: 50px;
        background-size: cover;
        background-position-y: center;
        display: flex;
        padding: 24px 76px;
        justify-content: space-between;
        max-height: 284px;
    }

    .home_contactForm__1bzb-{
        margin-left: 100px;
        margin-top: 0;
        align-self: center;
    }
    .home_contactInput__W2HXd{
        height: 28px;
        font-size: 20px;
        margin-bottom: 15px;
    }

    .home_contactBtn__3wX0K {
        background: black;
    }
}


@media (min-width: 1200px) {
    .home_newsLetter__1Jgpf{
        padding: 46px 76px;
        margin-right: 150px;
        margin-left: 150px;
    }

    .home_contactFormContainer__1WPPF{
        display: block;
    }
    .home_contactInput__W2HXd{
        height: 28px;
        margin-right: 10px;
        font-size: 20px;
        margin-bottom: 0;
    }
}

.card_card__Bcog8{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px;
}

.card_card__Bcog8:last-child{
    margin-right: 0;
}

.card_cardImg__2MyE8{
    border-radius: 50%;
    border: 8px solid #1F2231;
    width: 100px;
    height: auto;
    max-width: 250px;
    cursor: pointer;
}

.card_cardTitle__1lM_W{
    color: white;
    font-size:24px;
    margin-top: 45px;
}

.card_cardDescription__2C6rK{
    color: #9C9C9C;
    font-size: 20px;
    margin-top: 10px;
}

.productCard_cardWrapper__3SY72{
    border-radius: 15px;
    max-width: 491px;
    color: #FFB300;
    display: flex;
    background:  linear-gradient(to bottom, #FFE000 0%,#FF8900 100%) no-repeat top left,
    linear-gradient(to right,  #1F2231 0%, #1F2231 100%);
    background-size: 25% 100%;
    box-shadow: -20px 15px 32px 0px #00000070;
    margin-top: 18px;
    /*margin-right: 60px;*/
    width: 100%;
}

.productCard_cardWrapper__3SY72:nth-child(2n){
    margin-right: 0;
}

.productCard_cardImage__3Xvci{
    width: 131px;
    height: 131px;
    border-radius: 50%;
    margin: 10px 28px;
}

.productCard_cardDetails__3aluY{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 26px;
}

.productCard_cardTitle__1iZjt{
    color: white;
    font-size: 18px;
}

.productCard_cardPrice__1rekk{
    color: #FFB300;
    font-size: 24px;
}

@media (min-width: 480px) {
    .productCard_cardTitle__1iZjt{
        font-size: 24px;
    }
    
    .productCard_cardPrice__1rekk{
        font-size: 32px;
    }
}

@media (min-width: 768px) {
.productCard_cardWrapper__3SY72{
    margin-top: 44px;
    width: 382px;
}

}

@media (min-width: 992px) {
.productCard_cardWrapper__3SY72{
    width: 415px;
}
}


@media (min-width: 1200px) {
.productCard_cardWrapper__3SY72{
    max-width: 415px;
}
}

.product-display_title__9T5zc{
    font-size: 48px;
    color: white;
}

.product-display_mobileTitle__1qWnK{
    color: white;
}
h1{
    color: white;
}

.product-display_loadMoreContainer__2t9wj{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.product-display_mobileProductContainer__tYskk{
    margin-top: 36px;
}
.product-display_productContainer__3BGbq{
    width: 65%;
}

.product-display_products__y8YGK{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


.product-display_products__y8YGK > div:hover{
    cursor: pointer;
    background-color: rgb(230, 230, 230);
}

.product-display_spinner__40ncA{
    margin-top: 10em;
}

.product-display_spinnerMobile__1K3t8{
   justify-content: center;
   margin-top: 30%;
}


@media (min-width: 480px) {
    .product-display_productContainer__3BGbq{
        width: 100%;
    }
}

.product-info_popup__3w2Yx{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.product-info_popupInner__ERYNR {
    position: absolute;
    background-color: #1F2231;
    margin: auto;
    border-radius: 10px;
    padding: 3em;
}

.product-info_popupInner__ERYNR h1{
    color: white;
}

.product-info_popupInner__ERYNR .product-info_listingImage__3NwFm > img{
    margin-left: 10px;
    margin-top: 10px;
    width: 12vw;
    height:12vw;
}

.product-info_popupInner__ERYNR > .product-info_listingDescription__2E4Qd{
    height: 30vh;
}


.product-info_popupInnerMobile__2ZjvE{
    background: #1F2231;
    position: absolute;
    width: 95%;
    top: 10%;
    bottom: 10%;
    
    display: inline-block;
    border-radius: 15px;
    overflow-y: scroll;
    padding: 35px;
}

.product-info_popupInnerMobile__2ZjvE::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
}

.product-info_popupInnerMobile__2ZjvE::-webkit-scrollbar-track {
    opacity: 0;
}

.product-info_popupInnerMobile__2ZjvE::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.product-info_popupInnerMobile__2ZjvE > h5{
    position: absolute;
    bottom: 0;
}

.product-info_date__cJ4Ne{
    margin-top: 20px;
    color: white;
}

.product-info_popupInnerMobile__2ZjvE .product-info_listingImage__3NwFm > img{
    margin-left: 10px;
    margin-top: 20px;
}

.product-info_popupInnerMobile__2ZjvE > .product-info_listingDescription__2E4Qd{
    height: 40vh;
    margin: 3px;
}

.product-info_popupBasicInfo__28FDm{
  
    display: flex;
    flex-direction: column;
}



.product-info_productInfo__2gXUV{
    display: flex;
}
.product-info_productInfoWrapper__1yb_T{
    flex-direction: column;
}

.product-info_productInfoTitle__qLHZq{
    color: white;
}

.product-info_closeButton__3cOqi
{
    color: white;
    width: 80px;
}

.product-info_closeButton__3cOqi:hover{
    cursor: pointer;
}

.product-info_popUpSectionSelect__35x40{
    font-size: 20px;
    color: #505050;
    list-style-type: none;
    display: flex;
    padding: 0;
}

.product-info_popUpSectionSelect__35x40 li:first-child{
    margin-right: 55px;
}

.product-info_popUpSectionSelect__35x40 li span{
    cursor: pointer;
}

.product-info_listingTabs__1WKpy{
    font-size: 20px;
}

.product-info_listingTabsActive__3IoGp{
    color: orange;
    text-decoration: underline;
}

.product-info_deleteButtonMods__3Xepw {
    margin-left: 19px;
    margin-top: -8px;
    color:#ff7a7a;
    border-radius: 5px;
    border: 1px solid #FF6B6B;
    cursor: pointer;
    background-color: transparent;
    padding: 8px;
    font-size: 20px;
}



.product-info_productInfo__2gXUV{
    display: flex;
}

.product-info_proudctInfo__2ap05:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 50%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid white; /* This creates the border. Replace black with whatever color you want. */
}

.product-info_productDetails__3VNaE {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.product-info_productDetails__3VNaE:first-child{
    margin-right: 38px;
}

.product-info_productHeading__17TLh{
    color: #9C9C9C;
    font-size: 20px;
}

.product-info_horizontalRule__252JY{
    border: .5px solid #707070;
    width: 50%;
}

.product-info_profileDetails__J4dVC{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}



.product-info_profileTitle__u5Fes{
    font-size: 12px;
    /*font-size: 20px;*/
    color: #9C9C9C;
    font-weight: 300;
    display: block;
    margin-bottom: 4px;
}
.product-info_profileSecondary__3AqbN{
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.product-info_profileSecondaryLink__2wrmb{
    color: white;
    /*font-size: 24px;*/
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.product-info_profileReviewCount__3FQ9u{
    color: #FFB300;
    font-size: 19px;
    display: block;
}

.product-info_profileImage__G-QSk{
    border-radius: 50%;
    width: 84px;
    height: auto;
    max-height: 84px;
}

.product-info_profileData__3O_2o{
display: flex;
flex-direction: column;
width: 260px;
}

.product-info_logIn__2ndfP{
    color: white;
    padding: 2em;
    margin-left: 50px;
    width: 70%;

}

.product-info_profileUserDetails__AAAld{
    max-height: 75px;
    max-width: 380px;
    overflow-y: scroll;
    font-size: 12px;
    color: #9C9C9C;
    font-weight: 300;
    white-space: pre-line;    
}

.product-info_profileUserDetails__AAAld::-webkit-scrollbar {
    width: 3px;               /* width of the entire scrollbar */
}

.product-info_profileUserDetails__AAAld::-webkit-scrollbar-track {
    opacity: 0;
}

.product-info_profileUserDetails__AAAld::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.product-info_acceptingImage__TtZ72{
    border: 0px solid #707070;
    width: 28px;
    height: 28px;
    margin-bottom: 14px;
    margin-right: 17px;
}


.product-info_listingDescription__2E4Qd{
    border-top:solid #707070 1px;
    overflow-y: scroll;
    padding-top: 15px;
    margin-top: 20px;
    color: #9C9C9C;
    font-size: 20px;
    max-width: 300px;
    height: 100px;
    white-space: pre-line;    
    
}

.product-info_listingDescription__2E4Qd::-webkit-scrollbar {
    width: 3px;               /* width of the entire scrollbar */
}

.product-info_listingDescription__2E4Qd::-webkit-scrollbar-track {
    opacity: 0;
}

.product-info_listingDescription__2E4Qd::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.product-info_dmContainer__24ucS{
    flex-direction: column;
}

.product-info_dmBar__10ewl{
    /*margin-right: 10px;*/
    /*margin-bottom: 10px;*/
}

.product-info_dmTextArea__9W5T_{
    padding: 10px 14px 25px 14px !important;
    font-size: 20px;
    background: white;
    font-family: inherit;
    resize: none;
    border-radius: 15px;
    width: 100%;
    height: 135px;
    outline: none;
}

.product-info_dmTextArea__9W5T_::-webkit-input-placeholder{
    color: #9C9C9C;
}

.product-info_dmTextArea__9W5T_::-ms-input-placeholder{
    color: #9C9C9C;
}

.product-info_dmTextArea__9W5T_::placeholder{
    color: #9C9C9C;
}

.product-info_submitDM__1sey9{
    margin-top: 10px;
    margin-bottom: 10px;
}
.product-info_submitDM__1sey9 button{
    white-space: nowrap;

}



@media (min-width: 768px) {
    .product-info_popupBasicInfo__28FDm {
        flex-direction: row;
    }
    .product-info_dmContainer__24ucS{
        margin-left: 40px;
    }
}

@media (min-width: 500px) {
    .product-info_date__cJ4Ne{
        position: absolute;
        bottom: 0;
        color: white;
    }
}


@media (min-width: 992px) {
  .product-info_profileImage__G-QSk{
      width:150px;
      height: auto;
      max-height: 150px;
  }
    .product-info_profileTitle__u5Fes{
        font-size: 20px;
    }
    .product-info_profileSecondary__3AqbN{
        font-size: 24px;
    }
    .product-info_profileReviewCount__3FQ9u{
        font-size: 32px;
    }
    .product-info_profileUserDetails__AAAld{
        font-size:20px;
        max-height: 175px;
    }
    .product-info_profileSecondaryLink__2wrmb{
        font-size: 24px;
    }
    .product-info_acceptingImage__TtZ72{
        width: 42px;
    height: 42px;
    }
    .product-info_profileUserDetails__AAAld{
        width: 100%;   
    }
}


@media (min-width: 1200px) {

}

.filter-display_filterListContainer__1RtLd{
    display: flex;
    flex-direction: row-reverse;
}

.filter-display_filterListHeader__30FX4{
    font-size: 20px;
    color: #8D8D8D;
    margin-top: 22px;
}


.filter-display_filterList__3-mEl{
    list-style-type: none;
    color: #9C9C9C;
    margin-right: 85px;
}

.filter-display_filterBar__RypNv li{
    color: #9C9C9C;
    font-size: 20px;
}

.filter-display_filterBar__RypNv li:hover{
    /*background-color: blue;*/
    color: white;
    cursor: pointer;
}

@media (min-width: 480px) {
    .filter-display_productContainer__1Rz2-{
        width: 100%;
    }
    .filter-display_filterListContainer__1RtLd{
        display: block;
    }
    .filter-display_filterListHeader__30FX4{
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
    }
}


.categoryBar_topBar__20ivy{
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    list-style-type: none;
    width: 100%;
    margin: auto;
    margin-top: 5em;
}

.categoryBar_topBarMobile__3VJ4E{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 20px;
    list-style-type: none;
    width: 100%;
    margin-top: 5em;
}


.categoryBar_topBar__20ivy > button{
 text-align: center;
 background: none;
 color: #FFB300;
 font-size: 20px;
 font-family: inherit;
 outline:none;
 border: 0px;
 cursor: pointer;
 margin-right: 2%;
 margin-left: 2%;
}

.categoryBar_topBarMobile__3VJ4E > button{
    text-align: center;
    background: none;
    color: #FFB300;
    font-size: 20px;
    font-family: inherit;
    outline:none;
    border: 0px;
    cursor: pointer;
   
   }

.categoryBar_underlined__bQzIn{
    text-decoration: underline;
}
.browse_dashboard__3oKkp{
    min-height: 100vh;
}

.browse_dashWrapper__Lm6ww{
    display: flex;
    margin-top: 3em;
    flex-direction: column-reverse;
    margin-left: 21px;
    margin-right: 21px;
}

.browse_mobileButton__123r3{
    background: transparent linear-gradient(267deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    position: fixed !important;
    bottom: 25px;
    right: 25px;
}


@media (min-width: 992px) {
    .browse_mobileButton__123r3{
        display: none;
    }
    .browse_dashWrapper__Lm6ww{
        flex-direction: row;
    }
}



.TradecordTextField_input__1owhn{
    padding: 24px 25px;
    border-radius: 15px;
    height: 28px;
    border: none;
    margin-right: 10px;
    font-size: 20px;
    width: 100%;
    outline: none;
}

.TradecordTextField_input__1owhn::-webkit-input-placeholder{
    color: #9C9C9C;
    /*font-weight: lighter;*/
}

.TradecordTextField_input__1owhn::-ms-input-placeholder{
    color: #9C9C9C;
    /*font-weight: lighter;*/
}

.TradecordTextField_input__1owhn::placeholder{
    color: #9C9C9C;
    /*font-weight: lighter;*/
}

.create-listing_popup__120_V{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}


.create-listing_popupInner__2OYsk {
    position: absolute;
    background-color: #1F2231;
    margin: auto;
    border-radius: 10px;
    padding: 3em;
}

.create-listing_popupInner__2OYsk h1{
    color: white;
}

.create-listing_popupInner__2OYsk .create-listing_listingImage__ilG8Y > img{
    margin-left: 10px;
    margin-top: 10px;
    width: 12vw;
    height:12vw;
}

.create-listing_popupInner__2OYsk > .create-listing_listingDescription__2taC4{
    height: 30vh;
}


.create-listing_popupInnerMobile__21Zeg{
    background: #1F2231;
    position: absolute;
    width: 95%;
    top: 10%;
    bottom: 10%;
    margin: 0 34px;
    display: inline-block;
    border-radius: 15px;
    overflow-y: scroll;
    padding: 35px;
}

.create-listing_popupInnerMobile__21Zeg::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  
  }
  
  .create-listing_popupInnerMobile__21Zeg::-webkit-scrollbar-track {
    opacity: 0;
   
  }
  
  .create-listing_popupInnerMobile__21Zeg::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }


.create-listing_popupInnerMobile__21Zeg > h5{
    position: absolute;
    bottom: 0;
}


.create-listing_popupInnerMobile__21Zeg .create-listing_listingImage__ilG8Y > img{
    margin-left: 10px;
    margin-top: 20px;
}

.create-listing_popupInnerMobile__21Zeg > .create-listing_listingDescription__2taC4{
    height: 40vh;
    margin: 3px;
}

.create-listing_popupTitle__3fW66{
    font-size: 28px;
    color: white;
}

.create-listing_closeButton__1-JP7
{
    color: white;
    width: 80px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.create-listing_closeButton__1-JP7:hover{
    cursor: pointer;
}

.create-listing_textArea__1CyLd{
    padding: 10px 14px 25px 25px !important;
    font-size: 20px;
    background: white;
    font-family: inherit;
    resize: none;
    border-radius: 15px;
    width: 100%;
    height: 108px;
    margin: 0;
    outline: none;
    margin-bottom: 10px;
    display: block;
    border:0px
}

.create-listing_textArea__1CyLd::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  
  }
  
  .create-listing_textArea__1CyLd::-webkit-scrollbar-track {
    opacity: 0;
   
  }
  
  .create-listing_textArea__1CyLd::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }

.create-listing_textArea__1CyLd::-webkit-input-placeholder{
    color: #9C9C9C;
}

.create-listing_textArea__1CyLd::-ms-input-placeholder{
    color: #9C9C9C;
}

.create-listing_textArea__1CyLd::placeholder{
    color: #9C9C9C;
}

.create-listing_listingInfoContainer__2a7ij{
    margin-top: 40px;
    flex-direction: column;
}

.create-listing_listingInputWrapper__1LcUX{
    margin-bottom: 10px;
    margin-right: 10px;
    flex: 1 1;
}

.create-listing_select__aiej0{
    max-width: 100%;
}

.create-listing_listingSelectContainer__2Wq__{
    display:flex;
    flex-direction: column;
}

.create-listing_typeContainer__14xpX *{

    width: 50%;
  
}

.create-listing_buttonContainer__12OlI{

}

.create-listing_buttonContainer__12OlI button{
    font-size: 20px;
    padding: 7px 15px;
    width: 50%;
    margin-right: 10px;
}

@media (min-width: 768px) {
    .create-listing_listingInfoContainer__2a7ij{
        flex-direction: row;
    }
    .create-listing_listingSelectContainer__2Wq__{
        flex-direction: column;
    }
}

.confirmation_popup__IcVPo{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}


.confirmation_popupInner__29R6J {
    position: absolute;
    background-color: #1F2231;
    margin: auto;
    border-radius: 10px;
    padding: 3em;
}

.confirmation_popupInnerMobile__1BQDQ{
    background: #1F2231;
    position: absolute;
    margin:auto;
    display: inline-block;
    border-radius: 15px;
    overflow-y: scroll;
    padding: 35px;
}

.confirmation_popupInnerMobile__1BQDQ::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  
}
  
.confirmation_popupInnerMobile__1BQDQ::-webkit-scrollbar-track {
    opacity: 0;
   
}
  
.confirmation_popupInnerMobile__1BQDQ::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.confirmation_closeButton__1kNpQ
{
    color: white;
    width: 80px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.confirmation_closeButton__1kNpQ:hover{
    cursor: pointer;
}

.confirmation_popupTitle__1q1fV{
    font-size: 28px;
    color: white;
}

.confirmation_shareButton__2J1TK {
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    cursor: pointer;
    width: 200px;
    padding: 1em;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    text-align: center;
    margin-top: 10px;
}

.confirmation_support__2TZkB{
    width: 200px;
    color: white;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .confirmation_shareButton__2J1TK{
        width: 400px;
    }

    .confirmation_support__2TZkB{
        width: 400px;
    }
}

.sidebar_sidebar__3Rl-m{
    margin: 0 40px;
    align-self: center;
}

.sidebar_mobileButton__T4W_q{
    background: transparent linear-gradient(267deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    position: fixed !important;
    bottom: 25px;
    right: 25px;
}

.sidebar_browseButton__2_V_g{
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border: 0;
    color: #FFFFFF;
    height: 48px;
    padding: 0 30px;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0 16px 32px #FF890080;
    cursor: pointer;
    outline: none;
}

.sidebar_catSelect__3MX3r{
    margin-top: 30px;
}

.sidebar_filtersWrapper__3YJ42{
    margin-top: 20px;
}

.sidebar_buttonWidth__n8iI3{
    width:240px;
}

.sidebar_filterListContainer__3HfIM{
    display: flex;
    flex-direction: row-reverse;
}

.sidebar_filterListHeaderMobile__2LlSp{
    font-size: 20px;
    color: #8D8D8D;
    cursor: pointer;
    position: absolute;
    right:20px;
   top  :8em;
}

.sidebar_filterGroup__3ogd3{
    display: flex;
    flex-direction: column;
    padding: 0;
}

.sidebar_filterGroup__3ogd3 li {
    list-style: none;
}

.sidebar_filterRadioBtn__1VwW9{
    color: white;

}

@media (min-width: 480px) {
    .sidebar_filterListContainer__3HfIM{
        display: block;
    }
    .sidebar_filterListHeader__m5gHE{
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 22px;
        
    }
}

@media (min-width: 992px) {
    .sidebar_sidebar__3Rl-m{
        align-self: auto;
    }
    .sidebar_filterListHeader__m5gHE{
        cursor: inherit;
        margin-top: 22px;
    }

    .sidebar_filtersWrapper__3YJ42{
        margin-top: 80px;
    }
}



.termsofservice_tosHeader__2kJlj{
    margin-top: 100px;
    margin-left: 40px;
}
  
.termsofservice_termsList__38R7L{
    margin-left: 40px;
}
  
.termsofservice_terms__2WA0G{
    height: 100vh;
    color: white;
}

.manage_title__2VYLL{
    font-size: 48px;
    color: white;
}

.manage_manageWrapper__tOzLP{
    color: white;
}

.manage_filterBar__1LUPs{
color: #9C9C9C;
    margin-right: 180px;
}

.manage_filterBar__1LUPs li:hover, li:active{
    color:white;
    cursor: pointer;
}

/* TODO: Implement an active switch case for subcategory so this changes on filter selection */
.manage_filterListItemActive__tm4Ma{
    color: white;
}

.manage_dropMenu__3AU-0{
    float: right;
    margin-right: 5px;
    margin-top: 10px
}

.manage_spinner__1sLx6{
    margin-top: 10em;
}
  
.manage_spinnerMobile__2WPg5{
    margin-top: 10em;
    margin-left:-5em;
}

.manage_manageListings__2fx71{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.manage_manageListingsMobileOuter__3J30S{
    margin-top: 13em;
}
  
.manage_manageListingsMobile__19VfP{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
}
  
.manage_manageListingsMobile__19VfP > div{
    border-radius: 5px;
    margin-bottom: 10px; 
}
  
.manage_manageListingsMobile__19VfP > div > img{
    height: 50px;
    width: 50px;
    margin-top: 5px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}
  
.manage_manageListingsMobile__19VfP > div > .manage_dropMenu__3AU-0{
    float: right;
    margin-right: 5px;
    margin-top: 10px;
}

.manage_dashboard__1GdUJ{
    min-height: 100vh;  
}

.manage_dashWrapper__3eNhJ{
    width: 100%;
    display:flex;
    margin-top: 10em;
}


.manage_filterList__14Wzg{
    list-style-type: none;
    font-size: 20px;
}

.manage_manageListingTitle__p5S48{
    color: white;
}

.manage_subCatButton__1SkZY{
    width: 150px;

    position: absolute;
    top:8em;
    margin-left: 20px;
}


.manage_closeButton__3av4k{
    color: white;
    background-color: white;
}



@media (min-width: 480px) {

}

@media (min-width: 768px) {
.manage_filterBar__1LUPs{

    margin-right: 90px;
}
}

@media (min-width: 992px) {
.manage_filterBar__1LUPs{
    margin-right: 180px;
}
    .manage_manageWrapper__tOzLP{
        max-width: 1200px;
    }
}


@media (min-width: 1200px) {
}



.listing_listingWrapper__2QAEq{
    background-color: #1F2231;
    border-radius: 15px;
    padding: 17px 10px;
    width: 100%;
    max-height: 78px;
}

.listing_listingImage__yK_44{
    height: 57px;
    width: 57px;
    border-radius: 50%;
}

.listing_listingPrice__2Tvxd{
    margin-left: 15px;
    /*font-size: 32px;*/
    font-size: 23px;
color:#FFB300;
}

.listing_listingTitle__3lxQY{
    margin-left: 15px;
    text-align: center;
    color: white;
    /*font-size: 24px;*/
    font-size: 23px;
}

.listing_listingActionsContainer__1sWPU{

}

.listing_listingActionsContainer__1sWPU button{
    color: #9C9C9C;
    font-family: inherit;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: lighter;
}

.listing_listingActionsContainer__1sWPU button:first-child{
}

.listing_moreButton__2rUEV{
    color: white;
}


@media (min-width: 480px) {

}

@media (min-width: 768px) {
    .listing_listingWrapper__2QAEq{
        padding: 25px 40px 25px 30px;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {

}


@media (min-width: 1200px) {
    .listing_listingActionsContainer__1sWPU button:first-child{
        margin-right: 122px;
    }
}



/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 95%;
    margin-left: 2.5%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  

.membership_popup__1432e {  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0,0,0, 0.5);  
} 

.membership_cancelInner__3JIgY{
    position: absolute;
    background-color: #1F2231;
    color: white;
    height: auto; 
    width: 500px;
    margin:0px auto;
    left:0;
    right:0;
    margin-top: 200px;
    padding: 1em;
    border-radius: 5px;  
}
  
.membership_cancelInner__3JIgY > button{
    float: right;
    width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    height: 30px;
    border-radius: 5px;
    border: white 0px;
    cursor: pointer;
}
  
.membership_cancelInnerMobile__Ns-IN{
    position: absolute;
    background-color: #1F2231;
    color: white;
    height: auto; 
    width: 95%;
    margin:0px auto;
    left:0;
    right:0;
    margin-top: 200px;
    padding: 1em;
    border-radius: 5px;     
}
  
.membership_cancelInnerMobile__Ns-IN > button{
    float: right;
    width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    height: 30px;
    border-radius: 5px;
    border: white 0px;
    cursor: pointer;
}

  
.membership_cancel__2u9OZ{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,115,115,1) 0%, rgba(255,0,0,1) 100%);
    font-family: inherit;
    color: white;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
    outline: none;
}

.membership_stripeError__3kG2w{
    color: red;
    margin-left: 15px;
}

.membership_checkOutButton__2NYGq{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
    font-family: inherit;
    outline: none;
}

.membership_discountCode__nF9Q6{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    margin-top: 10px;
    height: 40px;
    padding: 10px 12px;
    width: 95%;
    margin-left: 2.5%;
    color: #32325d;
    background-color: #ffecd1;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
}

.membership_dashboard__ButMB{
    min-height: 100vh;  
}

.membership_lifeTime__3RG27{
    margin: auto;
    margin-top: 200px;
    width: 400px;
    text-align: center;
    border: 2px solid #d1d1d1;
    border-radius: 15px;
    font-size: 20px;
    background-color: #1F2231;
    color: white;
}

.membership_spinner__1tv2g{
    margin-top: 10em;
}
  
.membership_spinnerMobile__11fQy{
    margin-top: 50%;
    justify-content: center;
}

.membership_membership__pcRFF{
    margin: auto;
    margin-top: 160px;
    width: 400px;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
    padding: .5em;
}
  
.membership_membership__pcRFF > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}
  
.membership_membershipMobile__Mdjf1{
    margin: auto;
    margin-top: 160px;
    width: 95%;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
}
  
.membership_membershipMobile__Mdjf1 > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}

.membership_stripeTitle__2k9ju{
    text-align: center;
    font-size: 35px;
    color: white;
}

.membership_stripePrice__2c46B{
    text-align: center;
    color: #FFB300;
    font-size: 35px;
}

.membership_subActive__1yRMn{
    margin: auto;
    margin-top: 160px;
    width: 400px;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
    padding: .5em;
}
  
.membership_subActiveMobile__3ZLC9{
    margin: auto;
    margin-top: 160px;
    width: 95%;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
}

.membership_subActive__1yRMn > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}
  
.membership_subActiveMobile__3ZLC9 > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}

.membership_subP__2yUHn > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}

.membership_changePayment__1mt0F{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    font-family: inherit;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    outline: none;
}

.membership_resub__3x7i1{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    font-family: inherit;
    outline: none;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
}


.settings_settings__NLZpW{
    min-height: 100vh; 
}

.settings_settingsBox__2KPli{
    position: relative;
    background: #252733;
    border-radius: 15px;
    padding: 35px;
    display: flex;
    flex-direction: column;
}

.settings_twitterSelect__1O2OB{
    margin-top: 10%;
    width: 200px;
}

.settings_twitterSelectMobile__11Zq2{
    margin-top: 10%;
    margin-bottom: 50px;
}

.settings_titleText__1ad_x{
    color: white;
    font-size: 24px;
    margin-bottom: 17px;
}

.settings_bodyText__1HzO9{
    font-size: 17px;
    color: #9C9C9C;
}

.settings_bodyText__1HzO9 a{
    text-decoration: none;
}

.settings_clickable__cbHUy{
    cursor: pointer;
}

.settings_curWebhooks__394Ea{
    max-height: 300px;
    overflow-y: scroll;
}

.settings_noWebhooks__3OMHP{
    font-size: 20px;
    color: orange;
}

.settings_noWebhooksMobile__3I29H{
    font-size: 20px;
    color: orange;
    margin-bottom: 30px;
}

.settings_select__zuESH{
    width: 90%;
}

.settings_selectMobile__1SNS2{
    width: 100%;
}

.settings_curWebhooks__394Ea::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
  
.settings_curWebhooks__394Ea::-webkit-scrollbar-track {
    opacity: 0;
}
  
.settings_curWebhooks__394Ea::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.settings_bioText__2DEAG{
    overflow-y: scroll;
    max-height: 100px;
    white-space: pre-line; 
}

.settings_bioText__2DEAG::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
  
.settings_bioText__2DEAG::-webkit-scrollbar-track {
    opacity: 0;
}
  
.settings_bioText__2DEAG::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.settings_webhookWrapper__20ZdH{
    background: #161822;
    color: white;
    border-radius: 15px;
    padding: 11px 24px 11px 42px;
    font-size: 16px;
    margin-bottom: 15px;
}


.settings_gridContainer__36h8K{
    display: grid;
    grid-template-columns: 31.6% 31.6% 31.6%;
    grid-template-rows: 100px 300px;
    grid-column-gap: 2em;
    -webkit-column-gap: 2em;
            column-gap: 2em;
    grid-row-gap: 2em;
    row-gap: 2em;
    width: 90%;  
    height: 100vh; 
    margin: auto;
    margin-top: 100px;
}

.settings_gridContainerMobile__5rdcq{
    margin-top: 100px;
    display: flex;
    flex-direction: column;align-items: center;
}

.settings_gridContainerMobile__5rdcq > div{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 90%;
}

.settings_twitterAccount__DQpct{
    color: #FF8800;
    display:inline;
}

.settings_selectCat__LbkWI{
    width: 100%;
}


.settings_selectBot__1NUsg{
    width: 100%;
}

.settings_webhookLink__36vR3{
    height: 30px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    padding: 10px 12px;
    color: #32325d;
    background-color: #ffecd1;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
    outline:none;
}

.settings_webhookButton__2NNZt{
    height: 48px;
    width: 48px;
    padding: 0;
    background: linear-gradient(97deg, rgba(255,159,0,1) 0%, rgba(255,133,0,1) 100%);
    color: white;
    outline:none;
    cursor: pointer;
    border: 0px solid red;
    border-radius: 15px;

}

.settings_newWebhook__Xuzqn{
    display:grid;
    grid-template-columns: 25% 25% 33% 7%;
    grid-column-gap: 2%;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    
}

.settings_newWebhookMobile__1Krlr{
    display:grid;
    grid-template-columns: 25% 25% 33% 7%;
    grid-column-gap: 2%;
    bottom: 0;
    margin-top: 20px;
}

.settings_userWebhooks__wc_3y{
    height: 250px;
    width: 100%;
    overflow-y: scroll;
}

.settings_userWebhooks__wc_3y::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
  
.settings_userWebhooks__wc_3y::-webkit-scrollbar-track {
    background-color: #d6d6d6;       /* color of the tracking area */
}
  
.settings_userWebhooks__wc_3y::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.settings_webhookList__3jCio{
    width: 100%;
    display:grid;
    grid-template-columns: 25% 25% 30% 30px;
    grid-column-gap: 1em ;
    border-radius: 5px;
    box-shadow: -3px 4px 9px -1px rgba(189,189,189,1);
    margin-bottom: 1em;
}

.settings_webhookBotCat__2nn3Q{

    width: 100%;
    text-align: center;
}

.settings_webhookBotCat__2nn3Q > p{
    vertical-align: middle;
}

.settings_webhookBotName__2Vr1_{

    width: 100%;
    text-align: center;
}

.settings_webhookBotName__2Vr1_ > p{
    vertical-align: middle;
}

.settings_webhookTestContainer__AxcdM{
    position: relative;
}

.settings_webhookTestButton__1GBl-{
    margin-left: 10px;
    height: 30px;
    width: 100%;
    font-size: 16px;
    background: linear-gradient(97deg, rgba(255,159,0,1) 0%, rgba(255,133,0,1) 100%);
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    outline:none;
    cursor: pointer;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
    
}

.settings_webhookDeleteContainer__2get9{
    position: relative;
}

.settings_webhookDelete__Nochw{
   
    height: 30px;
    margin-left: 5px;
    width: 100%;
    padding: 0;
    background-color: #ff4d4d;
    outline:none;
    cursor: pointer;
    border: 0px solid red;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.settings_twitterAccounts__veBzG{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    padding: 1em;
    position: relative;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: span 2;    
}


.settings_saveButtonMobile__3C0gg{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: linear-gradient(97deg, rgba(255,159,0,1) 0%, rgba(255,133,0,1) 100%);
    border: 0px solid red;
    font-size: 17px;
    cursor: pointer;
    outline:none;
}


.settings_credit__1D7eZ{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    padding: 1em;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;  
  }

.settings_paymentMethods__3ejpS{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    position: relative;
    padding: 1em;  
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;  
}

.settings_webhooks__QQgm9{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    padding: 1em;  
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: span 2;
    position: relative;
    /*-webkit-filter: blur(5px);*/
}


.settings_spinner__1Sw-Y{
    margin-top: 10em;
}
  
.settings_spinnerMobile__hnsAV{
    
    margin: auto;
    margin-top: 15em;
}


.settings_paymentWrapper__C78LF{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.settings_acceptedPaymentWrapper__PWoOY{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right: 1em;
    margin-bottom: 1em;
    height: 63px;
    width: 63px;
    border-radius: 10px;
    cursor: pointer;
    background: #E6E6E6;
}

.settings_paymentImage__22pLn{
    height: 35px;
    width: 35px;
}

.settings_newWebhookContainer__Jn0S5{

}

.settings_newWebhookContainerMobile__2tg4o{
    margin-top: 20px;
    flex-direction: column;
}

  
.settings_paymentSelected__1PRg1{
    background: #FF9200;
}

.settings_paymentNotSelected__1AONo{
    background: rgb(230, 230, 230);
}


@media (max-width: 480px) {
    .settings_webhookWrapper__20ZdH{
        font-size: 12px;
    }
    
}

@media (min-width: 768px) {


}

@media (min-width: 1184px) {
    .settings_newWebhookContainer__Jn0S5{
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
    }
}


@media (min-width: 1200px) {
    .settings_titleText__1ad_x{
        font-size: 24px;
    }
}

.settings_popupInner__3Zw7O {  
    position: absolute;
    background-color: #1F2231;
    margin: auto;  
    border-radius: 10px;  
    padding: 3em;
}

.settings_popupInner__3Zw7O h1{
    color: white;
}

.settings_popup__2qReY{  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  } 

.settings_closeButton__2WirT
{
  /*position: absolute;*/
  /*right:15px;*/
  /*top: 15px;*/
    color: white;
    width: 80px;
    cursor: pointer;
}

.settings_bioTextArea__2XXVD{
    padding: 10px 14px 25px 14px !important;
    font-size: 20px;
    background: white;
    font-family: inherit;
    resize: none;
    border-radius: 15px;
    width: 100%;
    height: 135px;
    outline:none;
}

.settings_bioTextArea__2XXVD::-webkit-input-placeholder{
    color: #9C9C9C;
}

.settings_bioTextArea__2XXVD::-ms-input-placeholder{
    color: #9C9C9C;
}

.settings_bioTextArea__2XXVD::placeholder{
    color: #9C9C9C;
}

.settings_charCount__G8Pj4{
    color: white;
    float: right;
    width:50px;
}

.settings_charCountInvalid__PIO_8{
    color: red;
    float: right;
    width:50px;
}


.toast_toast__rKbGY{
    background:  #1F2231;
    color: white;
    border-radius: 15px;
    text-align: center;
    font-family: inherit;
}

.toast_toastBody__29v39{
    color: white;
}

.toast_progressBar__3j-pm{
    background:  linear-gradient(to bottom, #FFE000 0%,#FF8900 100%) no-repeat top left;
    border-radius: 15px;
}
