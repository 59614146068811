.settings{
    min-height: 100vh; 
}

.settingsBox{
    position: relative;
    background: #252733;
    border-radius: 15px;
    padding: 35px;
    display: flex;
    flex-direction: column;
}

.twitterSelect{
    margin-top: 10%;
    width: 200px;
}

.twitterSelectMobile{
    margin-top: 10%;
    margin-bottom: 50px;
}

.titleText{
    color: white;
    font-size: 24px;
    margin-bottom: 17px;
}

.bodyText{
    font-size: 17px;
    color: #9C9C9C;
}

.bodyText a{
    text-decoration: none;
}

.clickable{
    cursor: pointer;
}

.curWebhooks{
    max-height: 300px;
    overflow-y: scroll;
}

.noWebhooks{
    font-size: 20px;
    color: orange;
}

.noWebhooksMobile{
    font-size: 20px;
    color: orange;
    margin-bottom: 30px;
}

.select{
    width: 90%;
}

.selectMobile{
    width: 100%;
}

.curWebhooks::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
  
.curWebhooks::-webkit-scrollbar-track {
    opacity: 0;
}
  
.curWebhooks::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.bioText{
    overflow-y: scroll;
    max-height: 100px;
    white-space: pre-line; 
}

.bioText::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
  
.bioText::-webkit-scrollbar-track {
    opacity: 0;
}
  
.bioText::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.webhookWrapper{
    background: #161822;
    color: white;
    border-radius: 15px;
    padding: 11px 24px 11px 42px;
    font-size: 16px;
    margin-bottom: 15px;
}


.gridContainer{
    display: grid;
    grid-template-columns: 31.6% 31.6% 31.6%;
    grid-template-rows: 100px 300px;
    column-gap: 2em;
    row-gap: 2em;
    width: 90%;  
    height: 100vh; 
    margin: auto;
    margin-top: 100px;
}

.gridContainerMobile{
    margin-top: 100px;
    display: flex;
    flex-direction: column;align-items: center;
}

.gridContainerMobile > div{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 90%;
}

.twitterAccount{
    color: #FF8800;
    display:inline;
}

.selectCat{
    width: 100%;
}


.selectBot{
    width: 100%;
}

.webhookLink{
    height: 30px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    padding: 10px 12px;
    color: #32325d;
    background-color: #ffecd1;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    outline:none;
}

.webhookButton{
    height: 48px;
    width: 48px;
    padding: 0;
    background: linear-gradient(97deg, rgba(255,159,0,1) 0%, rgba(255,133,0,1) 100%);
    color: white;
    outline:none;
    cursor: pointer;
    border: 0px solid red;
    border-radius: 15px;

}

.newWebhook{
    display:grid;
    grid-template-columns: 25% 25% 33% 7%;
    grid-column-gap: 2%;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    
}

.newWebhookMobile{
    display:grid;
    grid-template-columns: 25% 25% 33% 7%;
    grid-column-gap: 2%;
    bottom: 0;
    margin-top: 20px;
}

.userWebhooks{
    height: 250px;
    width: 100%;
    overflow-y: scroll;
}

.userWebhooks::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}
  
.userWebhooks::-webkit-scrollbar-track {
    background-color: #d6d6d6;       /* color of the tracking area */
}
  
.userWebhooks::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.webhookList{
    width: 100%;
    display:grid;
    grid-template-columns: 25% 25% 30% 30px;
    grid-column-gap: 1em ;
    border-radius: 5px;
    box-shadow: -3px 4px 9px -1px rgba(189,189,189,1);
    margin-bottom: 1em;
}

.webhookBotCat{

    width: 100%;
    text-align: center;
}

.webhookBotCat > p{
    vertical-align: middle;
}

.webhookBotName{

    width: 100%;
    text-align: center;
}

.webhookBotName > p{
    vertical-align: middle;
}

.webhookTestContainer{
    position: relative;
}

.webhookTestButton{
    margin-left: 10px;
    height: 30px;
    width: 100%;
    font-size: 16px;
    background: linear-gradient(97deg, rgba(255,159,0,1) 0%, rgba(255,133,0,1) 100%);
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    outline:none;
    cursor: pointer;

  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
    
}

.webhookDeleteContainer{
    position: relative;
}

.webhookDelete{
   
    height: 30px;
    margin-left: 5px;
    width: 100%;
    padding: 0;
    background-color: #ff4d4d;
    outline:none;
    cursor: pointer;
    border: 0px solid red;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.twitterAccounts{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    padding: 1em;
    position: relative;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: span 2;    
}


.saveButtonMobile{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: linear-gradient(97deg, rgba(255,159,0,1) 0%, rgba(255,133,0,1) 100%);
    border: 0px solid red;
    font-size: 17px;
    cursor: pointer;
    outline:none;
}


.credit{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    padding: 1em;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;  
  }

.paymentMethods{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    position: relative;
    padding: 1em;  
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;  
}

.webhooks{
    box-shadow: 0px 0px 8px 4px #B0B0B0;
    padding: 1em;  
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: span 2;
    position: relative;
    /*-webkit-filter: blur(5px);*/
}


.spinner{
    margin-top: 10em;
}
  
.spinnerMobile{
    
    margin: auto;
    margin-top: 15em;
}


.paymentWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.acceptedPaymentWrapper{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right: 1em;
    margin-bottom: 1em;
    height: 63px;
    width: 63px;
    border-radius: 10px;
    cursor: pointer;
    background: #E6E6E6;
}

.paymentImage{
    height: 35px;
    width: 35px;
}

.newWebhookContainer{

}

.newWebhookContainerMobile{
    margin-top: 20px;
    flex-direction: column;
}

  
.paymentSelected{
    background: #FF9200;
}

.paymentNotSelected{
    background: rgb(230, 230, 230);
}


@media (max-width: 480px) {
    .webhookWrapper{
        font-size: 12px;
    }
    
}

@media (min-width: 768px) {


}

@media (min-width: 1184px) {
    .newWebhookContainer{
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
    }
}


@media (min-width: 1200px) {
    .titleText{
        font-size: 24px;
    }
}

.popupInner {  
    position: absolute;
    background-color: #1F2231;
    margin: auto;  
    border-radius: 10px;  
    padding: 3em;
}

.popupInner h1{
    color: white;
}

.popup{  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  } 

.closeButton
{
  /*position: absolute;*/
  /*right:15px;*/
  /*top: 15px;*/
    color: white;
    width: 80px;
    cursor: pointer;
}

.bioTextArea{
    padding: 10px 14px 25px 14px !important;
    font-size: 20px;
    background: white;
    font-family: inherit;
    resize: none;
    border-radius: 15px;
    width: 100%;
    height: 135px;
    outline:none;
}

.bioTextArea::placeholder{
    color: #9C9C9C;
}

.charCount{
    color: white;
    float: right;
    width:50px;
}

.charCountInvalid{
    color: red;
    float: right;
    width:50px;
}

