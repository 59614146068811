.title{
    font-size: 48px;
    color: white;
}

.manageWrapper{
    color: white;
}

.filterBar{
color: #9C9C9C;
    margin-right: 180px;
}

.filterBar li:hover, li:active{
    color:white;
    cursor: pointer;
}

/* TODO: Implement an active switch case for subcategory so this changes on filter selection */
.filterListItemActive{
    color: white;
}

.dropMenu{
    float: right;
    margin-right: 5px;
    margin-top: 10px
}

.spinner{
    margin-top: 10em;
}
  
.spinnerMobile{
    margin-top: 10em;
    margin-left:-5em;
}

.manageListings{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.manageListingsMobileOuter{
    margin-top: 13em;
}
  
.manageListingsMobile{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
}
  
.manageListingsMobile > div{
    border-radius: 5px;
    margin-bottom: 10px; 
}
  
.manageListingsMobile > div > img{
    height: 50px;
    width: 50px;
    margin-top: 5px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}
  
.manageListingsMobile > div > .dropMenu{
    float: right;
    margin-right: 5px;
    margin-top: 10px;
}

.dashboard{
    min-height: 100vh;  
}

.dashWrapper{
    width: 100%;
    display:flex;
    margin-top: 10em;
}


.filterList{
    list-style-type: none;
    font-size: 20px;
}

.manageListingTitle{
    color: white;
}

.subCatButton{
    width: 150px;

    position: absolute;
    top:8em;
    margin-left: 20px;
}


.closeButton{
    color: white;
    background-color: white;
}



@media (min-width: 480px) {

}

@media (min-width: 768px) {
.filterBar{

    margin-right: 90px;
}
}

@media (min-width: 992px) {
.filterBar{
    margin-right: 180px;
}
    .manageWrapper{
        max-width: 1200px;
    }
}


@media (min-width: 1200px) {
}


