
.popup {  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0,0,0, 0.5);  
} 

.cancelInner{
    position: absolute;
    background-color: #1F2231;
    color: white;
    height: auto; 
    width: 500px;
    margin:0px auto;
    left:0;
    right:0;
    margin-top: 200px;
    padding: 1em;
    border-radius: 5px;  
}
  
.cancelInner > button{
    float: right;
    width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    height: 30px;
    border-radius: 5px;
    border: white 0px;
    cursor: pointer;
}
  
.cancelInnerMobile{
    position: absolute;
    background-color: #1F2231;
    color: white;
    height: auto; 
    width: 95%;
    margin:0px auto;
    left:0;
    right:0;
    margin-top: 200px;
    padding: 1em;
    border-radius: 5px;     
}
  
.cancelInnerMobile > button{
    float: right;
    width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    height: 30px;
    border-radius: 5px;
    border: white 0px;
    cursor: pointer;
}

  
.cancel{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,115,115,1) 0%, rgba(255,0,0,1) 100%);
    font-family: inherit;
    color: white;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
    outline: none;
}

.stripeError{
    color: red;
    margin-left: 15px;
}

.checkOutButton{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
    font-family: inherit;
    outline: none;
}

.discountCode{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    margin-top: 10px;
    height: 40px;
    padding: 10px 12px;
    width: 95%;
    margin-left: 2.5%;
    color: #32325d;
    background-color: #ffecd1;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.dashboard{
    min-height: 100vh;  
}

.lifeTime{
    margin: auto;
    margin-top: 200px;
    width: 400px;
    text-align: center;
    border: 2px solid #d1d1d1;
    border-radius: 15px;
    font-size: 20px;
    background-color: #1F2231;
    color: white;
}

.spinner{
    margin-top: 10em;
}
  
.spinnerMobile{
    margin-top: 50%;
    justify-content: center;
}

.membership{
    margin: auto;
    margin-top: 160px;
    width: 400px;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
    padding: .5em;
}
  
.membership > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}
  
.membershipMobile{
    margin: auto;
    margin-top: 160px;
    width: 95%;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
}
  
.membershipMobile > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}

.stripeTitle{
    text-align: center;
    font-size: 35px;
    color: white;
}

.stripePrice{
    text-align: center;
    color: #FFB300;
    font-size: 35px;
}

.subActive{
    margin: auto;
    margin-top: 160px;
    width: 400px;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
    padding: .5em;
}
  
.subActiveMobile{
    margin: auto;
    margin-top: 160px;
    width: 95%;
    border: 0px solid #d1d1d1;
    height: auto;
    border-radius: 15px;
    background-color: #1F2231;
}

.subActive > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}
  
.subActiveMobile > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}

.subP > p{
    font-size: 18px;
    margin-left: 10px;
    color: white;
}

.changePayment{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    font-family: inherit;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    outline: none;
}

.resub{
    width: 95%;
    margin-left: 2.5%; 
    height: 45px;
    border-radius: 5px;
    background: transparent linear-gradient(269deg, #FF8900 0%, #FFE000 100%) 0% 0% no-repeat padding-box;
    font-family: inherit;
    outline: none;
    border: 0px solid red;
    margin-top: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
}

