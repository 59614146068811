.cardWrapper{
    border-radius: 15px;
    max-width: 491px;
    color: #FFB300;
    display: flex;
    background:  linear-gradient(to bottom, #FFE000 0%,#FF8900 100%) no-repeat top left,
    linear-gradient(to right,  #1F2231 0%, #1F2231 100%);
    background-size: 25% 100%;
    box-shadow: -20px 15px 32px 0px #00000070;
    margin-top: 18px;
    /*margin-right: 60px;*/
    width: 100%;
}

.cardWrapper:nth-child(2n){
    margin-right: 0;
}

.cardImage{
    width: 131px;
    height: 131px;
    border-radius: 50%;
    margin: 10px 28px;
}

.cardDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 26px;
}

.cardTitle{
    color: white;
    font-size: 18px;
}

.cardPrice{
    color: #FFB300;
    font-size: 24px;
}

@media (min-width: 480px) {
    .cardTitle{
        font-size: 24px;
    }
    
    .cardPrice{
        font-size: 32px;
    }
}

@media (min-width: 768px) {
.cardWrapper{
    margin-top: 44px;
    width: 382px;
}

}

@media (min-width: 992px) {
.cardWrapper{
    width: 415px;
}
}


@media (min-width: 1200px) {
.cardWrapper{
    max-width: 415px;
}
}
