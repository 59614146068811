@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  background-color: #161822 !important;
}

body{
  max-width: 1920px;
}

@media only screen and (min-width : 1919px) {
  body{
    margin: 0 auto !important;
  }
}

::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
     /* color of the tracking area */
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

code {
  
}

.menu {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.menuDark{
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.listItem{
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}


.menu li {
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.menuDark li {
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.navLink{
  text-decoration:none;
}

.navLink:visited{
  text-decoration: none;
  color: white;
}

.navLink:hover{
  cursor: pointer; 
}

.listItem{
  text-decoration: none;
  color: black;
}

.listItem:visited{
  text-decoration: none;
  color: black;
}

.listItem:hover{
  cursor: pointer; 
}

nav{
  position: fixed;
  background-color: #404040;
  width: 100%;
  z-index: 99 !important;
  box-shadow: 0 6px 15px -6px rgba(0,0,0,0.75);
}
