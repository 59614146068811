.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.popupInner {
    position: absolute;
    background-color: #1F2231;
    margin: auto;
    border-radius: 10px;
    padding: 3em;
}

.popupInner h1{
    color: white;
}

.popupInner .listingImage > img{
    margin-left: 10px;
    margin-top: 10px;
    width: 12vw;
    height:12vw;
}

.popupInner > .listingDescription{
    height: 30vh;
}


.popupInnerMobile{
    background: #1F2231;
    position: absolute;
    width: 95%;
    top: 10%;
    bottom: 10%;
    
    display: inline-block;
    border-radius: 15px;
    overflow-y: scroll;
    padding: 35px;
}

.popupInnerMobile::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
}

.popupInnerMobile::-webkit-scrollbar-track {
    opacity: 0;
}

.popupInnerMobile::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.popupInnerMobile > h5{
    position: absolute;
    bottom: 0;
}

.date{
    margin-top: 20px;
    color: white;
}

.popupInnerMobile .listingImage > img{
    margin-left: 10px;
    margin-top: 20px;
}

.popupInnerMobile > .listingDescription{
    height: 40vh;
    margin: 3px;
}

.popupBasicInfo{
  
    display: flex;
    flex-direction: column;
}



.productInfo{
    display: flex;
}
.productInfoWrapper{
    flex-direction: column;
}

.productInfoTitle{
    color: white;
}

.closeButton
{
    color: white;
    width: 80px;
}

.closeButton:hover{
    cursor: pointer;
}

.popUpSectionSelect{
    font-size: 20px;
    color: #505050;
    list-style-type: none;
    display: flex;
    padding: 0;
}

.popUpSectionSelect li:first-child{
    margin-right: 55px;
}

.popUpSectionSelect li span{
    cursor: pointer;
}

.listingTabs{
    font-size: 20px;
}

.listingTabsActive{
    color: orange;
    text-decoration: underline;
}

.deleteButtonMods {
    margin-left: 19px;
    margin-top: -8px;
    color:#ff7a7a;
    border-radius: 5px;
    border: 1px solid #FF6B6B;
    cursor: pointer;
    background-color: transparent;
    padding: 8px;
    font-size: 20px;
}



.productInfo{
    display: flex;
}

.proudctInfo:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 50%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid white; /* This creates the border. Replace black with whatever color you want. */
}

.productDetails {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.productDetails:first-child{
    margin-right: 38px;
}

.productHeading{
    color: #9C9C9C;
    font-size: 20px;
}

.horizontalRule{
    border: .5px solid #707070;
    width: 50%;
}

.profileDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}



.profileTitle{
    font-size: 12px;
    /*font-size: 20px;*/
    color: #9C9C9C;
    font-weight: 300;
    display: block;
    margin-bottom: 4px;
}
.profileSecondary{
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.profileSecondaryLink{
    color: white;
    /*font-size: 24px;*/
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.profileReviewCount{
    color: #FFB300;
    font-size: 19px;
    display: block;
}

.profileImage{
    border-radius: 50%;
    width: 84px;
    height: auto;
    max-height: 84px;
}

.profileData{
display: flex;
flex-direction: column;
width: 260px;
}

.logIn{
    color: white;
    padding: 2em;
    margin-left: 50px;
    width: 70%;

}

.profileUserDetails{
    max-height: 75px;
    max-width: 380px;
    overflow-y: scroll;
    font-size: 12px;
    color: #9C9C9C;
    font-weight: 300;
    white-space: pre-line;    
}

.profileUserDetails::-webkit-scrollbar {
    width: 3px;               /* width of the entire scrollbar */
}

.profileUserDetails::-webkit-scrollbar-track {
    opacity: 0;
}

.profileUserDetails::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}


.acceptingImage{
    border: 0px solid #707070;
    width: 28px;
    height: 28px;
    margin-bottom: 14px;
    margin-right: 17px;
}


.listingDescription{
    border-top:solid #707070 1px;
    overflow-y: scroll;
    padding-top: 15px;
    margin-top: 20px;
    color: #9C9C9C;
    font-size: 20px;
    max-width: 300px;
    height: 100px;
    white-space: pre-line;    
    
}

.listingDescription::-webkit-scrollbar {
    width: 3px;               /* width of the entire scrollbar */
}

.listingDescription::-webkit-scrollbar-track {
    opacity: 0;
}

.listingDescription::-webkit-scrollbar-thumb {
    background-color:  rgba(255,133,0,1);   /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

.dmContainer{
    flex-direction: column;
}

.dmBar{
    /*margin-right: 10px;*/
    /*margin-bottom: 10px;*/
}

.dmTextArea{
    padding: 10px 14px 25px 14px !important;
    font-size: 20px;
    background: white;
    font-family: inherit;
    resize: none;
    border-radius: 15px;
    width: 100%;
    height: 135px;
    outline: none;
}

.dmTextArea::placeholder{
    color: #9C9C9C;
}

.submitDM{
    margin-top: 10px;
    margin-bottom: 10px;
}
.submitDM button{
    white-space: nowrap;

}



@media (min-width: 768px) {
    .popupBasicInfo {
        flex-direction: row;
    }
    .dmContainer{
        margin-left: 40px;
    }
}

@media (min-width: 500px) {
    .date{
        position: absolute;
        bottom: 0;
        color: white;
    }
}


@media (min-width: 992px) {
  .profileImage{
      width:150px;
      height: auto;
      max-height: 150px;
  }
    .profileTitle{
        font-size: 20px;
    }
    .profileSecondary{
        font-size: 24px;
    }
    .profileReviewCount{
        font-size: 32px;
    }
    .profileUserDetails{
        font-size:20px;
        max-height: 175px;
    }
    .profileSecondaryLink{
        font-size: 24px;
    }
    .acceptingImage{
        width: 42px;
    height: 42px;
    }
    .profileUserDetails{
        width: 100%;   
    }
}


@media (min-width: 1200px) {

}
