.filterListContainer{
    display: flex;
    flex-direction: row-reverse;
}

.filterListHeader{
    font-size: 20px;
    color: #8D8D8D;
    margin-top: 22px;
}


.filterList{
    list-style-type: none;
    color: #9C9C9C;
    margin-right: 85px;
}

.filterBar li{
    color: #9C9C9C;
    font-size: 20px;
}

.filterBar li:hover{
    /*background-color: blue;*/
    color: white;
    cursor: pointer;
}

@media (min-width: 480px) {
    .productContainer{
        width: 100%;
    }
    .filterListContainer{
        display: block;
    }
    .filterListHeader{
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
    }
}

