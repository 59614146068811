.card{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px;
}

.card:last-child{
    margin-right: 0;
}

.cardImg{
    border-radius: 50%;
    border: 8px solid #1F2231;
    width: 100px;
    height: auto;
    max-width: 250px;
    cursor: pointer;
}

.cardTitle{
    color: white;
    font-size:24px;
    margin-top: 45px;
}

.cardDescription{
    color: #9C9C9C;
    font-size: 20px;
    margin-top: 10px;
}
